@import url('https://fonts.googleapis.com/css2?family=Radio+Canada&family=Silkscreen&display=swap');

// font-family: 'Radio Canada', sans-serif;
// font-family: 'Silkscreen', cursive;

$mobile-breakpoint: 480px;

.welcome-wagon {
    text-align: center;
    background-color: black;
    height: 100vh;
    width: 100%;
    .welcome-wagon-greeting-container {
      padding-top: 15vh;
      .welcome-wagon-greeting-header {
        font-family: 'Silkscreen', cursive;
        color: white;
        font-size: 50px;
      }
      .welcome-wagon-greeting-subtext {
        font-family: 'Silkscreen', cursive;
        color: white;
        margin: 0 5px;

      }
      
    }
}




  

.stable-image-fs {
  width: 100%;
}



//scrollbar 

html {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color, #888) var(--scrollbar-track-color, translucent);

  &::-webkit-scrollbar {
    width: 12px;
    @media (max-width: $mobile-breakpoint) {
        width: 1px;
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color, translucent);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color, #888);
    border-radius: 8px;
    &:hover {
      background-color: var(--scrollbar-thumb-hover-color, #666);
    }
  }
}