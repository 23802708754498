.floating-back-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .floating-back-button:hover {
    background-color: #0056b3;
  }


  .backgroundBoi {
      background-color: black;
  }