
@import url('https://fonts.googleapis.com/css2?family=Radio+Canada&family=Silkscreen&display=swap');

// font-family: 'Radio Canada', sans-serif;
// font-family: 'Silkscreen', cursive;

$mobile-breakpoint: 480px;

// Banner.scss
.banner {
    position: relative;
    width: 100%;
    overflow: hidden;
  
    &__image {
        width: 100%;
        height: auto;
        transition: filter 0.2s ease-in-out; // Add a transition for the filter effect
      }
      
      &__image--blurred {
        filter: blur(4px); // Apply the blur effect to the image
      }
  
    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  
    &__title {
      font-size: 2rem;
      margin-bottom: 1rem;
      font-family: 'Silkscreen', cursive;
      transition: color 0.7s ease-in-out, border-color 0.7s ease-in-out;
      @media (max-width: $mobile-breakpoint) {
        font-size: 1.3rem;
      }
    }
  
    &__text {
      font-size: 1.4rem;
      margin-bottom: 1rem;
      font-family: 'Silkscreen', cursive;
      transition: color 0.7s ease-in-out, border-color 0.7s ease-in-out;
      @media (max-width: $mobile-breakpoint) {
        font-size: 0.65rem;
      }
    }
  
    &__button {
        background-color: rgba(92, 107, 192, 0.5); // Semi-transparent background
        border: 1px solid rgba(255, 255, 255, 0.5); // Semi-transparent border
        border-radius: 3px;
        color: #fff;
        font-size: 1.6rem;
        font-family: 'Silkscreen', cursive;
        padding: 0.5rem 1rem;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        backdrop-filter: blur(10px); // Apply a blur effect to the elements behind the button
        -webkit-backdrop-filter: blur(10px); // For better browser compatibility
        @media (max-width: $mobile-breakpoint) {
            font-size: 0.65rem;
          }
      
        &:hover {
          background-color: rgba(63, 81, 181, 0.7); // Increase opacity on hover
          border-color: rgba(255, 255, 255, 0.7); // Increase border opacity on hover
        }
      }
  }